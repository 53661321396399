//ブレイクポイントを設定
// $bp_pc:1024px;
$bp_pc:1100px;
$bp_tablet :767px;
$bp_sp: 640px;
$bp_sp_more: 320px;
//メディアクエリ用のミックスイン
@mixin media($media-width, $w: max) {

    @if $media-width == pc{
      @media only screen and ($w + -width: $bp_pc) {
           @content;
      }
    }

    @else if $media-width == tablet{
       @media only screen and ($w + -width: $bp_tablet) {
            @content;
       }
   }

     @else if $media-width == sp{
        @media only screen and ($w + -width: $bp_sp) {
             @content;
        }
    }
    @else if $media-width == sp_more {
         @media only screen and ($w + -width: $bp_sp_more){
            @content;
        }
    }
    @else {
         @media only screen and ($w + -width: $media-width + px){
            @content;
        }
    }
}

@mixin media-min($media-width, $w: max) {
	@media only screen and ($w + -width: $media-width + px){
            @content;
    }
}