
$yellow_l: #fefce2;
$l_yellow: #fdfbed;
$btn: #8ea1a3;
$btn_bg: #e8edee;
$bg: #faf8f2;
$purple: #913adb;
$base_c: #2990c6;
$bg_c: #f4f1e9;
$bg_c2: #f0f2ed;
$base_light_c: #f3f3f3;
$dark_b: #0b4e71;
$blue2: #00a0e9;
$line2: #d0d0d0;
$pink: #ee43b9;
$line: #c3c3c3;
$gold_l: #f4efda;
$gold: #c8ae46;
$gray_l: #f5f5e9;
$gray: #7a7a7a;



$blue: #004ba5;
$blue_l: #2385ea;
$orange: #ff7700;
$green: #06c755;
$red: #ed1b24;